/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ChangelogIdVersionVersionIdImport } from './routes/changelog.$id.version.$versionId'

// Create Virtual Routes

const CliLazyImport = createFileRoute('/cli')()
const IndexLazyImport = createFileRoute('/')()
const UserIndexLazyImport = createFileRoute('/user/')()
const PageIndexLazyImport = createFileRoute('/page/')()
const ChangelogIndexLazyImport = createFileRoute('/changelog/')()
const AccessTokensIndexLazyImport = createFileRoute('/access-tokens/')()
const PageCreateLazyImport = createFileRoute('/page/create')()
const PageIdLazyImport = createFileRoute('/page/$id')()
const ChangelogCreateLazyImport = createFileRoute('/changelog/create')()
const ChangelogIdLazyImport = createFileRoute('/changelog/$id')()
const AccessTokensNewLazyImport = createFileRoute('/access-tokens/new')()
const PageIdIndexLazyImport = createFileRoute('/page/$id/')()
const ChangelogIdIndexLazyImport = createFileRoute('/changelog/$id/')()
const PageIdEditLazyImport = createFileRoute('/page/$id/edit')()
const ChangelogIdVersionCreateLazyImport = createFileRoute(
  '/changelog/$id/versionCreate',
)()
const ChangelogIdEditLazyImport = createFileRoute('/changelog/$id/edit')()

// Create/Update Routes

const CliLazyRoute = CliLazyImport.update({
  id: '/cli',
  path: '/cli',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/cli.lazy').then((d) => d.Route))

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const UserIndexLazyRoute = UserIndexLazyImport.update({
  id: '/user/',
  path: '/user/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/user/index.lazy').then((d) => d.Route))

const PageIndexLazyRoute = PageIndexLazyImport.update({
  id: '/page/',
  path: '/page/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/page.index.lazy').then((d) => d.Route))

const ChangelogIndexLazyRoute = ChangelogIndexLazyImport.update({
  id: '/changelog/',
  path: '/changelog/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/changelog.index.lazy').then((d) => d.Route),
)

const AccessTokensIndexLazyRoute = AccessTokensIndexLazyImport.update({
  id: '/access-tokens/',
  path: '/access-tokens/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/access-tokens.index.lazy').then((d) => d.Route),
)

const PageCreateLazyRoute = PageCreateLazyImport.update({
  id: '/page/create',
  path: '/page/create',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/page.create.lazy').then((d) => d.Route))

const PageIdLazyRoute = PageIdLazyImport.update({
  id: '/page/$id',
  path: '/page/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/page.$id.lazy').then((d) => d.Route))

const ChangelogCreateLazyRoute = ChangelogCreateLazyImport.update({
  id: '/changelog/create',
  path: '/changelog/create',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/changelog.create.lazy').then((d) => d.Route),
)

const ChangelogIdLazyRoute = ChangelogIdLazyImport.update({
  id: '/changelog/$id',
  path: '/changelog/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/changelog.$id.lazy').then((d) => d.Route))

const AccessTokensNewLazyRoute = AccessTokensNewLazyImport.update({
  id: '/access-tokens/new',
  path: '/access-tokens/new',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/access-tokens.new.lazy').then((d) => d.Route),
)

const PageIdIndexLazyRoute = PageIdIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PageIdLazyRoute,
} as any).lazy(() =>
  import('./routes/page.$id.index.lazy').then((d) => d.Route),
)

const ChangelogIdIndexLazyRoute = ChangelogIdIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ChangelogIdLazyRoute,
} as any).lazy(() =>
  import('./routes/changelog.$id.index.lazy').then((d) => d.Route),
)

const PageIdEditLazyRoute = PageIdEditLazyImport.update({
  id: '/edit',
  path: '/edit',
  getParentRoute: () => PageIdLazyRoute,
} as any).lazy(() => import('./routes/page.$id.edit.lazy').then((d) => d.Route))

const ChangelogIdVersionCreateLazyRoute =
  ChangelogIdVersionCreateLazyImport.update({
    id: '/versionCreate',
    path: '/versionCreate',
    getParentRoute: () => ChangelogIdLazyRoute,
  } as any).lazy(() =>
    import('./routes/changelog.$id.versionCreate.lazy').then((d) => d.Route),
  )

const ChangelogIdEditLazyRoute = ChangelogIdEditLazyImport.update({
  id: '/edit',
  path: '/edit',
  getParentRoute: () => ChangelogIdLazyRoute,
} as any).lazy(() =>
  import('./routes/changelog.$id.edit.lazy').then((d) => d.Route),
)

const ChangelogIdVersionVersionIdRoute =
  ChangelogIdVersionVersionIdImport.update({
    id: '/version/$versionId',
    path: '/version/$versionId',
    getParentRoute: () => ChangelogIdLazyRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/cli': {
      id: '/cli'
      path: '/cli'
      fullPath: '/cli'
      preLoaderRoute: typeof CliLazyImport
      parentRoute: typeof rootRoute
    }
    '/access-tokens/new': {
      id: '/access-tokens/new'
      path: '/access-tokens/new'
      fullPath: '/access-tokens/new'
      preLoaderRoute: typeof AccessTokensNewLazyImport
      parentRoute: typeof rootRoute
    }
    '/changelog/$id': {
      id: '/changelog/$id'
      path: '/changelog/$id'
      fullPath: '/changelog/$id'
      preLoaderRoute: typeof ChangelogIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/changelog/create': {
      id: '/changelog/create'
      path: '/changelog/create'
      fullPath: '/changelog/create'
      preLoaderRoute: typeof ChangelogCreateLazyImport
      parentRoute: typeof rootRoute
    }
    '/page/$id': {
      id: '/page/$id'
      path: '/page/$id'
      fullPath: '/page/$id'
      preLoaderRoute: typeof PageIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/page/create': {
      id: '/page/create'
      path: '/page/create'
      fullPath: '/page/create'
      preLoaderRoute: typeof PageCreateLazyImport
      parentRoute: typeof rootRoute
    }
    '/access-tokens/': {
      id: '/access-tokens/'
      path: '/access-tokens'
      fullPath: '/access-tokens'
      preLoaderRoute: typeof AccessTokensIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/changelog/': {
      id: '/changelog/'
      path: '/changelog'
      fullPath: '/changelog'
      preLoaderRoute: typeof ChangelogIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/page/': {
      id: '/page/'
      path: '/page'
      fullPath: '/page'
      preLoaderRoute: typeof PageIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/user/': {
      id: '/user/'
      path: '/user'
      fullPath: '/user'
      preLoaderRoute: typeof UserIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/changelog/$id/edit': {
      id: '/changelog/$id/edit'
      path: '/edit'
      fullPath: '/changelog/$id/edit'
      preLoaderRoute: typeof ChangelogIdEditLazyImport
      parentRoute: typeof ChangelogIdLazyImport
    }
    '/changelog/$id/versionCreate': {
      id: '/changelog/$id/versionCreate'
      path: '/versionCreate'
      fullPath: '/changelog/$id/versionCreate'
      preLoaderRoute: typeof ChangelogIdVersionCreateLazyImport
      parentRoute: typeof ChangelogIdLazyImport
    }
    '/page/$id/edit': {
      id: '/page/$id/edit'
      path: '/edit'
      fullPath: '/page/$id/edit'
      preLoaderRoute: typeof PageIdEditLazyImport
      parentRoute: typeof PageIdLazyImport
    }
    '/changelog/$id/': {
      id: '/changelog/$id/'
      path: '/'
      fullPath: '/changelog/$id/'
      preLoaderRoute: typeof ChangelogIdIndexLazyImport
      parentRoute: typeof ChangelogIdLazyImport
    }
    '/page/$id/': {
      id: '/page/$id/'
      path: '/'
      fullPath: '/page/$id/'
      preLoaderRoute: typeof PageIdIndexLazyImport
      parentRoute: typeof PageIdLazyImport
    }
    '/changelog/$id/version/$versionId': {
      id: '/changelog/$id/version/$versionId'
      path: '/version/$versionId'
      fullPath: '/changelog/$id/version/$versionId'
      preLoaderRoute: typeof ChangelogIdVersionVersionIdImport
      parentRoute: typeof ChangelogIdLazyImport
    }
  }
}

// Create and export the route tree

interface ChangelogIdLazyRouteChildren {
  ChangelogIdEditLazyRoute: typeof ChangelogIdEditLazyRoute
  ChangelogIdVersionCreateLazyRoute: typeof ChangelogIdVersionCreateLazyRoute
  ChangelogIdIndexLazyRoute: typeof ChangelogIdIndexLazyRoute
  ChangelogIdVersionVersionIdRoute: typeof ChangelogIdVersionVersionIdRoute
}

const ChangelogIdLazyRouteChildren: ChangelogIdLazyRouteChildren = {
  ChangelogIdEditLazyRoute: ChangelogIdEditLazyRoute,
  ChangelogIdVersionCreateLazyRoute: ChangelogIdVersionCreateLazyRoute,
  ChangelogIdIndexLazyRoute: ChangelogIdIndexLazyRoute,
  ChangelogIdVersionVersionIdRoute: ChangelogIdVersionVersionIdRoute,
}

const ChangelogIdLazyRouteWithChildren = ChangelogIdLazyRoute._addFileChildren(
  ChangelogIdLazyRouteChildren,
)

interface PageIdLazyRouteChildren {
  PageIdEditLazyRoute: typeof PageIdEditLazyRoute
  PageIdIndexLazyRoute: typeof PageIdIndexLazyRoute
}

const PageIdLazyRouteChildren: PageIdLazyRouteChildren = {
  PageIdEditLazyRoute: PageIdEditLazyRoute,
  PageIdIndexLazyRoute: PageIdIndexLazyRoute,
}

const PageIdLazyRouteWithChildren = PageIdLazyRoute._addFileChildren(
  PageIdLazyRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/cli': typeof CliLazyRoute
  '/access-tokens/new': typeof AccessTokensNewLazyRoute
  '/changelog/$id': typeof ChangelogIdLazyRouteWithChildren
  '/changelog/create': typeof ChangelogCreateLazyRoute
  '/page/$id': typeof PageIdLazyRouteWithChildren
  '/page/create': typeof PageCreateLazyRoute
  '/access-tokens': typeof AccessTokensIndexLazyRoute
  '/changelog': typeof ChangelogIndexLazyRoute
  '/page': typeof PageIndexLazyRoute
  '/user': typeof UserIndexLazyRoute
  '/changelog/$id/edit': typeof ChangelogIdEditLazyRoute
  '/changelog/$id/versionCreate': typeof ChangelogIdVersionCreateLazyRoute
  '/page/$id/edit': typeof PageIdEditLazyRoute
  '/changelog/$id/': typeof ChangelogIdIndexLazyRoute
  '/page/$id/': typeof PageIdIndexLazyRoute
  '/changelog/$id/version/$versionId': typeof ChangelogIdVersionVersionIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/cli': typeof CliLazyRoute
  '/access-tokens/new': typeof AccessTokensNewLazyRoute
  '/changelog/create': typeof ChangelogCreateLazyRoute
  '/page/create': typeof PageCreateLazyRoute
  '/access-tokens': typeof AccessTokensIndexLazyRoute
  '/changelog': typeof ChangelogIndexLazyRoute
  '/page': typeof PageIndexLazyRoute
  '/user': typeof UserIndexLazyRoute
  '/changelog/$id/edit': typeof ChangelogIdEditLazyRoute
  '/changelog/$id/versionCreate': typeof ChangelogIdVersionCreateLazyRoute
  '/page/$id/edit': typeof PageIdEditLazyRoute
  '/changelog/$id': typeof ChangelogIdIndexLazyRoute
  '/page/$id': typeof PageIdIndexLazyRoute
  '/changelog/$id/version/$versionId': typeof ChangelogIdVersionVersionIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/cli': typeof CliLazyRoute
  '/access-tokens/new': typeof AccessTokensNewLazyRoute
  '/changelog/$id': typeof ChangelogIdLazyRouteWithChildren
  '/changelog/create': typeof ChangelogCreateLazyRoute
  '/page/$id': typeof PageIdLazyRouteWithChildren
  '/page/create': typeof PageCreateLazyRoute
  '/access-tokens/': typeof AccessTokensIndexLazyRoute
  '/changelog/': typeof ChangelogIndexLazyRoute
  '/page/': typeof PageIndexLazyRoute
  '/user/': typeof UserIndexLazyRoute
  '/changelog/$id/edit': typeof ChangelogIdEditLazyRoute
  '/changelog/$id/versionCreate': typeof ChangelogIdVersionCreateLazyRoute
  '/page/$id/edit': typeof PageIdEditLazyRoute
  '/changelog/$id/': typeof ChangelogIdIndexLazyRoute
  '/page/$id/': typeof PageIdIndexLazyRoute
  '/changelog/$id/version/$versionId': typeof ChangelogIdVersionVersionIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/cli'
    | '/access-tokens/new'
    | '/changelog/$id'
    | '/changelog/create'
    | '/page/$id'
    | '/page/create'
    | '/access-tokens'
    | '/changelog'
    | '/page'
    | '/user'
    | '/changelog/$id/edit'
    | '/changelog/$id/versionCreate'
    | '/page/$id/edit'
    | '/changelog/$id/'
    | '/page/$id/'
    | '/changelog/$id/version/$versionId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/cli'
    | '/access-tokens/new'
    | '/changelog/create'
    | '/page/create'
    | '/access-tokens'
    | '/changelog'
    | '/page'
    | '/user'
    | '/changelog/$id/edit'
    | '/changelog/$id/versionCreate'
    | '/page/$id/edit'
    | '/changelog/$id'
    | '/page/$id'
    | '/changelog/$id/version/$versionId'
  id:
    | '__root__'
    | '/'
    | '/cli'
    | '/access-tokens/new'
    | '/changelog/$id'
    | '/changelog/create'
    | '/page/$id'
    | '/page/create'
    | '/access-tokens/'
    | '/changelog/'
    | '/page/'
    | '/user/'
    | '/changelog/$id/edit'
    | '/changelog/$id/versionCreate'
    | '/page/$id/edit'
    | '/changelog/$id/'
    | '/page/$id/'
    | '/changelog/$id/version/$versionId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  CliLazyRoute: typeof CliLazyRoute
  AccessTokensNewLazyRoute: typeof AccessTokensNewLazyRoute
  ChangelogIdLazyRoute: typeof ChangelogIdLazyRouteWithChildren
  ChangelogCreateLazyRoute: typeof ChangelogCreateLazyRoute
  PageIdLazyRoute: typeof PageIdLazyRouteWithChildren
  PageCreateLazyRoute: typeof PageCreateLazyRoute
  AccessTokensIndexLazyRoute: typeof AccessTokensIndexLazyRoute
  ChangelogIndexLazyRoute: typeof ChangelogIndexLazyRoute
  PageIndexLazyRoute: typeof PageIndexLazyRoute
  UserIndexLazyRoute: typeof UserIndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  CliLazyRoute: CliLazyRoute,
  AccessTokensNewLazyRoute: AccessTokensNewLazyRoute,
  ChangelogIdLazyRoute: ChangelogIdLazyRouteWithChildren,
  ChangelogCreateLazyRoute: ChangelogCreateLazyRoute,
  PageIdLazyRoute: PageIdLazyRouteWithChildren,
  PageCreateLazyRoute: PageCreateLazyRoute,
  AccessTokensIndexLazyRoute: AccessTokensIndexLazyRoute,
  ChangelogIndexLazyRoute: ChangelogIndexLazyRoute,
  PageIndexLazyRoute: PageIndexLazyRoute,
  UserIndexLazyRoute: UserIndexLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/cli",
        "/access-tokens/new",
        "/changelog/$id",
        "/changelog/create",
        "/page/$id",
        "/page/create",
        "/access-tokens/",
        "/changelog/",
        "/page/",
        "/user/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/cli": {
      "filePath": "cli.lazy.tsx"
    },
    "/access-tokens/new": {
      "filePath": "access-tokens.new.lazy.tsx"
    },
    "/changelog/$id": {
      "filePath": "changelog.$id.lazy.tsx",
      "children": [
        "/changelog/$id/edit",
        "/changelog/$id/versionCreate",
        "/changelog/$id/",
        "/changelog/$id/version/$versionId"
      ]
    },
    "/changelog/create": {
      "filePath": "changelog.create.lazy.tsx"
    },
    "/page/$id": {
      "filePath": "page.$id.lazy.tsx",
      "children": [
        "/page/$id/edit",
        "/page/$id/"
      ]
    },
    "/page/create": {
      "filePath": "page.create.lazy.tsx"
    },
    "/access-tokens/": {
      "filePath": "access-tokens.index.lazy.tsx"
    },
    "/changelog/": {
      "filePath": "changelog.index.lazy.tsx"
    },
    "/page/": {
      "filePath": "page.index.lazy.tsx"
    },
    "/user/": {
      "filePath": "user/index.lazy.tsx"
    },
    "/changelog/$id/edit": {
      "filePath": "changelog.$id.edit.lazy.tsx",
      "parent": "/changelog/$id"
    },
    "/changelog/$id/versionCreate": {
      "filePath": "changelog.$id.versionCreate.lazy.tsx",
      "parent": "/changelog/$id"
    },
    "/page/$id/edit": {
      "filePath": "page.$id.edit.lazy.tsx",
      "parent": "/page/$id"
    },
    "/changelog/$id/": {
      "filePath": "changelog.$id.index.lazy.tsx",
      "parent": "/changelog/$id"
    },
    "/page/$id/": {
      "filePath": "page.$id.index.lazy.tsx",
      "parent": "/page/$id"
    },
    "/changelog/$id/version/$versionId": {
      "filePath": "changelog.$id.version.$versionId.tsx",
      "parent": "/changelog/$id"
    }
  }
}
ROUTE_MANIFEST_END */
